import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import * as Icons from "../components/Icons"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUsBodyS1AbstractShape from "../images/artwork/AboutUs-BodyS1-AbstractShape_v1.svg"
import AboutUsBodyS1GradientLine from "../images/artwork/AboutUs-BodyS1-GradientLine_v1.svg"
import AboutUsCTAGraphics from "../images/artwork/AboutUs-CTA-Graphics_v2.svg"
import AboutUsCTAMobile from "../images/artwork/AboutUs-CTA-Mobile_v1.svg"
import AboutUsCTATablet from "../images/artwork/AboutUs-CTA-Tablet_v1.svg"
import AboutUsHeroGraphics from "../images/artwork/AboutUs-Hero-Graphics_v4.svg"
import AboutUsHeroMobile from "../images/artwork/AboutUs-Hero-Mobile_v2.svg"
import AboutUsMissionGraphics from "../images/artwork/AboutUs-Mission-Graphics_v1.svg"
import AboutUsPartnershipGraphics from "../images/artwork/AboutUs-Partnership-Graphics_v1.svg"
import AboutUsVisionGraphics from "../images/artwork/AboutUs-Vision-Graphics_v1.svg"
import LogoIcon from "../images/logo.png"

import JustinIcon from "../images/Profile-Justin_v1.svg"
import VanessaIcon from "../images/Profile-Vanessa_v2.svg"
import ZachIcon from "../images/Profile-Zach_v1.svg"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <PageContainer>
      <Containers.SectionContainer
        style={{
          flexDirection: "column",
        }}
      >
        <HeroContent>
          <h1>Change starts from within</h1>
        </HeroContent>
        <HeroBg src={AboutUsHeroGraphics} alt="AboutUs" style={{}} />
        <HeroBgMobile src={AboutUsHeroMobile} alt="AboutUs" style={{}} />
      </Containers.SectionContainer>

      <MVSectionContainer>
        {/* <FadeComponent> */}
        <MissionContainer>
          <MissionContent>
            <h2>Mission</h2>
            <h5>
              <span>To be your handhold towards digital empowerment</span>
            </h5>
          </MissionContent>
          <MissionGraphics src={AboutUsMissionGraphics} alt="Handhold" />
        </MissionContainer>
        {/* </FadeComponent> */}

        <GradientLine src={AboutUsBodyS1GradientLine} alt="" />
        <AbstractShape src={AboutUsBodyS1AbstractShape} alt="" />
        {/* <FadeComponent> */}
        <VisionContainer>
          <VisionGraphics src={AboutUsVisionGraphics} alt="Success" />
          <VisionContent>
            <h2>Vision</h2>
            <h5>
              <span>
                To place the industry’s movers and shakers at the core of our
                innovations, with a collective commitment to achieve business
                success and contribute to enterprise & human sustainability
              </span>
            </h5>
          </VisionContent>
        </VisionContainer>
        {/* </FadeComponent> */}
      </MVSectionContainer>

      <Containers.SectionContainer
        style={{ marginTop: "4%", flexDirection: "column" }}
      >
        <StoryContainer>
          <h2>
            We are a group of individuals,
            <br /> who have had their lives touched by insurance
          </h2>
          <p>
            Uuoni is founded by a group of individuals who believe that the many
            facets of insurance can be vastly improved by technology. To drive
            this positive change in the industry, we recognise that the
            industry’s agents and brokers are at the core of shaping the
            industry’s digital development.
          </p>
          <p>
            Through technology and accompanying UX Design methodologies, we aim
            to spur digital adoption among the professionals. On a deeper level,
            this elevates their professional standing by empowering them with
            personalised, digital solutions.
          </p>
          <p>
            We invite you to join us on this journey together by sharing with
            you one of our core principles, <br />
            <span style={{ color: "#222222", fontWeight: 600 }}>
              Change Stems From Within.
            </span>
          </p>
        </StoryContainer>
        <OurStory>
          <p
            className="title"
            style={{
              transform: "translateX(8%)",
            }}
          >
            OUR STORY
          </p>
        </OurStory>
      </Containers.SectionContainer>
      <Containers.SectionContainer
        style={{
          maxWidth: "100%",
        }}
      >
        <PartnersBgContainer
          src={AboutUsPartnershipGraphics}
          alt="Partnership"
        />
        <PartnersContainer>
          <h2>Partnering Together For Success</h2>
          <p style={{ marginBottom: 30 }}>
            We are big believers in collaboration and cooperation, and are
            looking to build a new world with our esteemed partners. If you
            think there is an opportunity for us to succeed together, we would
            like to hear from you.
          </p>
          <Buttons.ButtonLinkArrowed to="/contact" style={{ paddingLeft: 0 }}>
            Let's work together
            <Icons.RightArrowIcon />
          </Buttons.ButtonLinkArrowed>
        </PartnersContainer>
      </Containers.SectionContainer>

      <Containers.SectionContainer>
        <TeamContainer>
          <h2
            style={{
              alignSelf: "flex-start",
            }}
          >
            Meet the Team
          </h2>
          <Containers.ProfileContainer>
            <Containers.ProfileContainerItem>
              <Containers.ProfilePicture
                style={{
                  backgroundImage: `url(${JustinIcon})`,
                }}
              />
              <h5>Justin Neo</h5>
              <PositionText>Chief Technology Officer & Co-Founder</PositionText>
            </Containers.ProfileContainerItem>
            <Containers.ProfileContainerItem>
              <Containers.ProfilePicture
                style={{
                  backgroundImage: `url(${VanessaIcon})`,
                }}
              />
              <h5>Vanessa Lee</h5>
              <PositionText>Chief Customer Officer</PositionText>
            </Containers.ProfileContainerItem>
            <Containers.ProfileContainerItem>
              <Containers.ProfilePicture
                style={{
                  backgroundImage: `url(${ZachIcon})`,
                }}
              />
              <h5>Zac Lim</h5>
              <PositionText>UX Researcher</PositionText>
            </Containers.ProfileContainerItem>
          </Containers.ProfileContainer>
        </TeamContainer>
      </Containers.SectionContainer>

      <Containers.SectionContainer style={{ maxWidth: "100%" }}>
        <CTAContainer>
          <CTAContentContainer>
            <CTAHeader>Lead the change with us</CTAHeader>
            <CTAMobileBgContainer src={AboutUsCTAMobile} alt="Leadership" />

            <p>
              Uuoni is an everyday platform for aspiring insurance professionals
              – supporting them behind-the-scenes, enabling them to value-add
              their clients on a personal level. We seek to unravel greater
              potential by delivering complementary solutions for the agents of
              the industry.
            </p>
            <p>If our vision resonates with you, we welcome you to join us!</p>
            <Buttons.ButtonLink to="/contact">Contact Us</Buttons.ButtonLink>
          </CTAContentContainer>
          <CTABgContainer src={AboutUsCTAGraphics} alt="Leadership" />
          <CTATabletBgContainer src={AboutUsCTATablet} alt="Leadership" />
        </CTAContainer>
      </Containers.SectionContainer>
    </PageContainer>
  </Layout>
)

export default AboutPage

const PageContainer = styled(Containers.PageContainer)`
  @media screen and (max-width: 1024px) {
    gap: 1.5rem;
  }
`

const FadeComponent = styled(Fade)`
  @media screen and (min-width: 1025px) {
  }

  @media screen and (max-width: 1024px) {
  }
`

const PositionText = styled.h6`
  font-weight: 500;
  width: 70%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const HeroBg = styled.img`
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    transform: scale(1.1);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const HeroBgMobile = styled.img`
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const OurStory = styled.div`
  margin-top: 8%;
  align-self: flex-end;
  white-space: nowrap;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const HeroContent = styled.div`
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`

const MVSectionContainer = styled(Containers.SectionContainer)`
  flex-direction: column;
  max-width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`

const MissionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const MissionContent = styled.div`
  @media screen and (min-width: 768px) {
    width: 75%;
    padding-left: 5%;
    margin-bottom: 5%;
  }
`

const MissionGraphics = styled.img`
  width: 27%;
  z-index: 1;

  @media screen and (max-width: 767px) {
    width: 55%;
    transform: translateX(51%);
  }
`

const VisionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 5%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const VisionContent = styled.div`
  @media screen and (min-width: 768px) {
    width: 48%;
    padding-right: 5%;
    margin-bottom: 5%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    order: 1;
  }
`

const VisionGraphics = styled.img`
  @media screen and (min-width: 1025px) {
    width: 47%;
    transform: translateX(-26%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 47%;
    transform: scale(1.1) translate(-19%, 10%);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    transform: scale(1.3);
    margin-top: 1rem;
    order: 2;
  }
`

const StoryContent = styled.div`
  @media screen and (min-width: 1025px) {
    width: 65%;
  }
`

const PartnersBgContainer = styled.img`
  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    transform: translate(-12%, -8%);
    height: 500px;
  }

  @media screen and (max-width: 767px) {
    align-self: start;
    order: 2;
    transform: scale(1.15) translate(1%, 8%);
    margin-bottom: 5rem;
  }
`

const PartnersContainer = styled.div`
  @media screen and (min-width: 1025px) {
    margin-top: 10%;
    transform: translateX(-8%);
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 10%;
  }

  @media screen and (max-width: 1024px) {
    order: 1;
  }
`

const PartnersContent = styled.div`
  @media screen and (min-width: 1025px) {
    width: 65%;
  }
`

const TeamContent = styled.div`
  display: flex;
  width: inherit;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

// const CTABg = styled.div`
//   position: relative;
//   background-image: url(${FeaturesIndivCTAGraphics});
//   background-repeat: no-repeat;
//   background-size: 50%;
//   background-position: left;
//   height: 100%;
//   width: 100%;

//   @media screen and (max-width: 1024px) {
//     background-image: none;
//   }
// `

const CTAContent = styled.div`
  @media screen and (min-width: 1025px) {
    align-self: flex-end;
    margin-top: auto;
    margin-bottom: auto;
    width: 45%;
  }
`

const GradientLine = styled.img`
  z-index: -10;
  margin: 0px;

  @media screen and (min-width: 1025px) {
    position: absolute;
    top: 10%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    top: 10%;
  }

  @media screen and (max-width: 767px) {
    margin-top: -34%;
    transform: scale(1.11);
  }
`

const AbstractShape = styled.img`
  z-index: -20;
  margin: 0px;

  @media screen and (min-width: 1025px) {
    position: absolute;
    top: 25%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const StoryContainer = styled.div`
  @media screen and (min-width: 1025px) {
    width: 60%;
  }

  @media screen and (max-width: 1024px) {
  }
`

const CTAContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (min-width: 1025px) {
    max-width: 100%;
    height: min(1080px, 75vh);
    align-items: center;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    max-width: 100%;
    align-items: flex-end;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const CTAHeader = styled.h2`
  @media screen and (min-width: 1025px) {
  }

  @media screen and (max-width: 767px) {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 10vw;
  }
`

const CTABgContainer = styled.img`
  transform: scale(1.5);

  @media screen and (min-width: 1025px) {
    z-index: -20;
    width: 20%;
    transform: scale(5) translateX(-15%);
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const CTAMobileBgContainer = styled.img`
  transform: scale(1.13);
  z-index: -1;
  position: relative;
  margin-top: 10%;

  @media screen and (min-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
  }
`

const CTATabletBgContainer = styled.img`
  transform: scale(1.13);
  z-index: -20;
  width: 30%;
  margin-left: auto;

  @media screen and (min-width: 1025px) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const CTAContentContainer = styled.div`
  @media screen and (min-width: 1025px) {
    width: 55%;
    padding-left: 5%;
    text-align: left;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 65%;
    text-align: left;
  }

  @media screen and (max-width: 767px) {
    order: 2;
    position: relative;
  }
`

const TeamContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-top: 10%;
  }
`
